/* Colors */
$primary-color: #4467fe;
$white-color: #ffffff;
$black-color: #000000;
$border-color: #e3e4e6;
$text-color: #808288;
$danger-color: #fe4444;
$input-bg-color: #f5f5f6;
$file-card-bg: rgba(245, 245, 246, 1);
$light-gray: rgba(209, 210, 214, 1);

/* Fonts */
$font-family-sans-serif: "Product Sans", sans-serif;
$font-extra: 64px;
$font-xxl: 32px;
$font-xl: 24px;
$font-lg: 20px;
$font-md: 18px;
$font-sm: 16px;
$font-xs: 14px;
$font-xxs: 12px;
$font-xxxs: 9px;
$font-boldest: 900;
$font-bold: 700;
$font-semibold: 600;
$font-medium: 500;
$font-regular: 400;
$font-light: 300;
$font-lightest: 100;

/* Device sizes */
$mobile: 768px;
$tablet: 990px;
$desktop: 1200px;
$large-desktop: 1400px;

/* Chat content width */
$content-width: 1056px;

/* Main */
$gap: 16px; /* use for margins and paddings */

// Border Radius
$radius: 16px;
