@mixin titleStyle {
  font-size: $font-xl;
  font-weight: $font-semibold;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $gap;
  min-height: 60px;
  flex-wrap: wrap;

  .left-side {
    flex: 2;
    display: flex;
    align-items: center;
    min-width: 50%;
    gap: $gap / 2;
  }

  .right-side {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: $gap;
  }

  .title {
    @include titleStyle();
    &.mobile-title {
      display: none;
    }
  }

  .ant-btn-primary {
    display: flex;
    align-items: center;
  }

  .search-bar {
    .ant-input-affix-wrapper {
      border-radius: 16px !important;
      .ant-input-prefix {
        color: $text-color;
      }
    }
    input {
      font-size: $font-sm !important;
      padding-top: $gap / 2.5 !important;
      padding-bottom: $gap / 2.5 !important;
    }
  }

  .ant-avatar {
    cursor: pointer;
  }
  .ant-btn {
    min-height: 48px;
    font-size: $font-sm;
    border-radius: $radius;
    font-weight: $font-medium;
  }

  .hamburger {
    display: none;
    cursor: pointer;
  }

  @media only screen and (max-width: $tablet) {
    .greeting {
      display: none;
    }
    .hamburger,
    .mobile-title {
      display: block !important;
    }
  }
}
