.cards-container {
  display: flex;
  gap: $gap;
  flex-wrap: wrap;
  align-items: center;

  img {
    pointer-events: none;
    -webkit-user-select: none;
    user-select: none;
  }

  .chart-container {
    .chart-wrapper {
      width: 250px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .legend-container {
        width: -moz-fit-content;
        width: fit-content;

        & .used,
        .remain {
          position: relative;
          &::before {
            position: absolute;
            content: "";
            width: 12px;
            height: 12px;
            left: -20px;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
          }
        }
        & .used::before {
          background-color: #aeafb6;
        }
        & .remain::before {
          background-color: $primary-color;
        }
      }
    }
  }

  .card-main {
    border: none;
    border-radius: $radius;
    width: 190px;
    height: fit-content;
    flex-grow: 1;

    .card-inner {
      display: flex;
      flex-direction: column;
      gap: $gap / 2;

      .card-title {
        font-size: $font-lg;
        font-weight: $font-medium;
        text-transform: capitalize;
      }

      .card-subtitle {
        font-size: $font-md;
        font-weight: $font-regular;
        color: $text-color;
        text-transform: capitalize;
      }

      .card-desc {
        font-size: $font-sm;
        font-weight: $font-medium;
        color: $black-color;
        margin-top: $gap;
      }
    }
  }
  @media only screen and (max-width: $mobile) {
    gap: $gap * 2;
    .chart-container,
    .card-main {
      width: 100%;
    }
  }
}
