main.login-page {
  min-height: 100vh;
  max-width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;

  .branding-side {
    width: 50%;
    min-height: 100vh;
    background: url("../assets/images/login-bg.png") center no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .logo {
      max-width: 250px;
    }
    .brand-title {
      font-size: $font-extra;
      margin: 0;
    }
    .brand-desc {
      font-size: $font-xxl;
      text-align: center;
      max-width: 463px;
    }
  }

  .form-side {
    width: 50%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .form-title {
      font-size: 40px;
      font-weight: $font-semibold;
      text-align: center;
      margin-bottom: $gap * 2;
    }

    form {
      min-width: 400px;

      .ant-form-item {
        margin-bottom: $gap * 2;
      }

      label {
        color: $black-color;
        font-size: $font-lg;
        font-weight: $font-medium;
      }

      .remember {
        label {
          color: #808288 !important;
        }
      }

      .ant-input-outlined {
        font-size: $font-lg;
        padding: $gap / 2 $gap;
        background-color: $input-bg-color !important;
        border-radius: $gap;
      }

      .single-column {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      .forgot-pass {
        color: $danger-color;
        font-size: $font-lg;
      }
    }
    .or {
      text-align: center;
      font-size: $font-lg;
      font-weight: $font-medium;
    }

    .google-login-btn,
    .ant-btn-primary {
      width: 100%;
      background-color: $primary-color;
      color: $white-color;
      font-size: $font-lg !important;
      border-radius: $radius !important;
      font-weight: $font-semibold !important;
      height: 50px;
    }
    .google-login-btn {
      background-color: transparent !important;
      justify-content: center;
      border: 1px solid $primary-color !important;
      box-shadow: none !important;
      color: $black-color !important;
      div {
        background-color: transparent !important;
      }
    }
  }

  @media only screen and (max-width: $tablet) {
    .branding-side {
      display: none;
    }

    .form-side {
      background: url("../assets/images/login-bg.png") center no-repeat;
      width: 100%;
    }
  }

  @media only screen and (max-width: $mobile) {
    .form-side {
      form {
        min-width: 100%;
        label,
        .forgot-pass {
          font-size: $font-md;
        }
      }
      .google-login-btn,
      .ant-btn-primary {
        font-size: $font-md !important;
      }
    }
  }
}
