@import "variables";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

$body-font-family: "Roboto", sans-serif;

$code-font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;

* {
  box-sizing: border-box;
  font-family: $body-font-family !important;
}

body {
  font-family: $body-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;

  code {
    font-family: $code-font-family !important;
    display: block;
  }

  pre {
    margin: 0px;
  }
}
