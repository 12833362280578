.file-card {
  .ant-card {
    box-shadow: none;
    background-color: $file-card-bg !important;
    border-radius: $radius + 4;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    cursor: pointer;

    .ant-card-head {
      border: none;
      .anticon {
        font-size: $font-xl;
        font-weight: $font-bold;
        cursor: pointer;
        color: rgba(156, 158, 166, 1);
      }
    }
    .ant-card-body {
      text-align: center;

      .file-name {
        font-size: $font-lg;
        font-weight: $font-medium;
        text-transform: capitalize;
        line-height: 50px;
      }
      .sub-file-name {
        position: relative;
        bottom: -$gap;
        background-color: $white-color;
        border-radius: $radius - 4;
        padding: 0 $gap * 2;
        line-height: 44px;
      }
    }
  }

  .ant-checkbox .ant-checkbox-inner {
    background-color: $light-gray;
    border-radius: 50%;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $primary-color;
  }
}
