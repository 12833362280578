@import "variables";
@import "header";
@import "cards";
@import "aside";
@import "login";
@import "file_card";

@mixin boxShadow() {
  box-shadow: 0px 4px 16px 0px rgba(156, 158, 166, 0.15);
  border: 1px solid $border-color;
}

@mixin titleStyle {
  font-size: $font-xl;
  font-weight: $font-semibold;
}

.Layout {
  display: flex;

  .main {
    width: 100%;
  }

  .container {
    padding: $gap;
    max-height: calc(100vh - 76px);
    overflow: auto;
  }

  section {
    margin: $gap * 2 0;
    padding: $gap * 2;
  }

  .section-title {
    font-size: $font-xxl;
    font-weight: $font-medium;
    margin-bottom: $gap;
  }

  .dashboard .analytics {
    background-color: $white-color;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: $gap;
    min-height: 400px;
    border-radius: $radius;
    @include boxShadow();
  }

  .table-title {
    font-size: $font-lg;
    font-weight: $font-medium;
    color: $black-color;
  }

  .recent-files {
    td.ant-table-cell {
      font-size: $font-sm;
      color: $text-color;
      font-weight: 400;

      .file-name {
        display: flex;
        align-items: center;
        gap: $gap;
      }
    }

    span.anticon.anticon-ellipsis {
      font-size: $font-xl;
    }
  }

  .page-title {
    font-size: $font-xl;
    font-weight: $font-semibold;
  }
}

.logout-modal-footer {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: $gap;
}

.ant-btn-primary {
  background-color: $primary-color;
}

.ant-checkbox .ant-checkbox-inner {
  width: 24px;
  height: 24px;
  border-radius: 6px;

  &::after {
    width: 7.714286px;
    height: 11.142857px;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary-color;
}

.ant-btn-text {
  color: $text-color;
}

.flex-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hidden {
  visibility: hidden;
}

.ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

section:not(.analytics) {
  padding: 0 !important;
}

.mobile-menu {
  .ant-drawer-body {
    padding: 0 !important;
  }
  .close-menu {
    font-size: $font-lg !important;
    color: $text-color;
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
