aside {
  height: 100vh;
  max-width: 300px !important;
  min-width: 250px !important;
  padding: $gap;
  border-right: 1px solid $border-color;
  &.mobile {
    max-width: 100% !important;
    width: 100% !important;
    border: none !important;

    .ant-menu {
      border: none !important;
    }
  }

  .menu {
    margin-top: $gap;

    li {
      margin-bottom: $gap !important;
      min-height: 50px;
    }

    .ant-menu-item {
      color: $text-color;
      font-size: $font-md;
      font-weight: $font-regular;
      transition: font-weight 0.5s ease;

      svg path {
        fill: $text-color !important;
      }
    }
    .ant-menu-item-selected {
      color: $primary-color !important;
      font-weight: $font-semibold;

      svg path {
        fill: $primary-color !important;
      }
    }
    .menu-items {
      display: flex;
      align-items: center;
      gap: $gap / 2;
    }
  }

  .branding {
    text-align: center;

    img {
      cursor: pointer;
    }
  }

  @media only screen and (max-width: $tablet) {
    display: none;
    &.mobile {
      display: block;
    }
  }
}
